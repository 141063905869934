import React, { useEffect, useState, useContext } from 'react';
import { Container, Card, Table, Row } from 'react-bootstrap';
import { IconCheck, IconCopy, IconEdit, IconPlus, IconTrash, IconChevronDown } from '@tabler/icons';
import {
  Text,
  Group,
  Button,
  TextInput,
  ActionIcon,
  Center,
  Select,
  Flex,
  Tooltip,
} from '@mantine/core';
import Col from 'react-bootstrap/Col';
import AddMaterial from './AddMaterial';
import EditMaterial from './EditMaterial';
import CloneMaterial from './CloneMaterial';
import DeleteMaterial from './DeleteMaterial';
import projectService from '../../services/projectService';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { UserContext } from '../../context/userContext';
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { InfoCircle, Space } from 'tabler-icons-react';
import CustomCardLoader from '../../components/CustomCardLoader';
import { ActiveUserContext } from '../../context/activerUserContext';
import { check_roles } from '../../utils/Utils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

export default function ProjectRequest() {
  const navigate = useNavigate();
  const {
    loading,
    loadProjects,
    project,
    setProject,
    selectedMaterial,
    setSelectedMaterial,
    createProject,
    updateProject,
    getDraftProjectById,
  } = useContext(UserContext);

  let params = useParams();

  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [showEditMaterialModal, setShowEditMaterialModal] = useState(false);
  const [showCloneMaterialModal, setShowCloneMaterialModal] = useState(false);
  const [showDeleteMaterialModal, setShowDeleteMaterialModal] = useState(false);
  const [materials, setMaterials] = useState([]);
  // const [projectName, setProjectName] = useState();
  // const [projectDescription, setProjectDescription] = useState();
  // const [projectType, setProjectType] = useState();
  const [projectDraftStatus, setProjectDraftStatus] = useState(false);
  const [projectTypeList, setProjectTypeList] = useState([]);
  const [projectYearList, setProjectYearList] = useState([]);
  // const [projectYear, setProjectYear] = useState();
  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState();
  const [deleteMaterialList, setDeleteMaterialList] = useState([]);
  const [userState, userDispatch] = useContext(ActiveUserContext);

  useEffect(() => {
    //console.log('loading project details');
    if (params?.id) getDraftProjectById(params?.id);
    else {
      // setProjectName('');
      // setProjectDescription('');
      // setProjectType();
      // setProjectYear();
      formik.setFieldValue('projectName', '');
      formik.setFieldValue('projectDescription', '');
      formik.setFieldValue('projectType', '');
      formik.setFieldValue('projectYear', '');
      setMaterials([]);
      setProject({});
    }
  }, [params?.id]);

  useEffect(() => {
    //console.log('draftProject ', project);
    if (project) {
      // setProjectName(project?.project_name);
      // setProjectType(project?.project_type);
      // setProjectDescription(project?.project_description);
      // setProjectYear(project?.project_year);
      formik.setFieldValue('projectName', project?.project_name);
      formik.setFieldValue('projectDescription', project?.project_description);
      formik.setFieldValue('projectType', project?.project_type);
      formik.setFieldValue('projectYear', project?.project_year);
      setProjectDraftStatus(project?.draft_status);
      setMaterials(project?.materials ? project?.materials : []);
    }
  }, [project]);

  useEffect(() => {
    projectService.getProjectTypes().then((res) => {
      if (res.data) {
        setProjectTypeList(res.data?.projectTypes?.map((projectType) => projectType.project_type));
      }
    });
    setSelectedMaterial({});

    return function cleanup() {
      setProject({});
    };
  }, []);

  const addMaterial = (materialObject) => {
    //console.log('materialObject', materialObject);
    setMaterials(materials.concat([materialObject]));
  };

  const updateMaterial = (materialObject) => {
    setMaterials(
      materials
        .filter((material, index) => index !== selectedMaterialIndex)
        .concat([materialObject]),
    );
  };

  const deleteMaterial = () => {
    const deletedMaterial = materials.filter((material, index) => index === selectedMaterialIndex);
    setDeleteMaterialList(deleteMaterialList.concat(deletedMaterial));
    setMaterials(materials.filter((material, index) => index !== selectedMaterialIndex));
  };

  const formik = useFormik({
    initialValues: {
      projectName: '',
      projectDescription: '',
      projectType: '',
      projectYear: '',
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required('Project Name is required'),
      projectDescription: Yup.string().required('Project Description is required'),
      projectType: Yup.string().required('Project Type is required'),
      projectYear: Yup.string().required('Project Year is required'),
    }),
    enableReinitialize: true,
    // validate: (values) => {
    //   const errors = {};
    //   if (!values.projectName) errors.projectName = 'Project Name is required';
    //   if (!values.projectDescription) errors.projectDescription = 'Project Description is required';
    //   if (!values.projectType) errors.projectType = 'Project Type is required';
    //   if (!values.projectYear) errors.projectYear = 'Project Year is required';
    //   return errors;
    // },
    onSubmit: (values, { setSubmitting }) => {
      //console.log('MATERIALS', materials);
      if (params?.id) {
        updateProject({
          id: project?.id,
          ...values,
          materials: materials,
          deletedMaterials: deleteMaterialList,
          draftStatus: projectDraftStatus,
        });
      } else {
        if (materials.length === 0) {
          return toast.error('Please add at least one material to the project');
        } else {
          createProject({
            ...values,
            materials: materials,
            draftStatus: false,
          });
        }
      }
      formik.resetForm();
    },
  });

  if (!userState.roles) {
    return <CustomCardLoader />;
  }

  return (
    <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
      {loading ? (
        <Card.Body>
          <CustomCardLoader />
        </Card.Body>
      ) : (
        <>
          {check_roles(userState, ['admin', 'requestor']) ? (
            <Card>
              <Card.Body>
                <Group position="apart">
                  <Text
                    size="xl"
                    weight={700}
                    color="yellow"
                    align="left"
                    style={{ fontSize: '18px' }}
                  >{`New Project - Project Request Form`}</Text>
                </Group>
              </Card.Body>
              <Card.Body>
                <Flex
                  mih={50}
                  gap="md"
                  justify="left"
                  align="left"
                  direction="column"
                  align-items=" flex-end"
                  wrap="wrap"
                >
                  <Row>
                    <Group spacing="lg">
                      <Col lg="3" md="3" sm="3" xs="3" align="right">
                        <Text>Project Name</Text>
                      </Col>
                      <Col lg="6" md="8" sm="8" xs="6" align="left">
                        <TextInput
                          radius={'xl'}
                          placeholder={`Project Name`}
                          // size="md"
                          onChange={(e) => {
                            formik.setFieldValue('projectName', e.target.value);
                          }}
                          value={formik.values.projectName}
                          error={formik.errors.projectName}
                          // disabled={formik.isSubmitting}
                        ></TextInput>
                      </Col>
                    </Group>
                  </Row>
                  <Row>
                    <Group spacing="lg">
                      <Col lg="3" md="3" sm="3" xs="3" align="right">
                        <Flex
                          gap="xm"
                          justify="right"
                          // align="center"
                          direction="row"
                          wrap="nowrap"
                        >
                          <Text>Project Description</Text>
                          <Tooltip
                            multiline
                            width={220}
                            withArrow
                            transition="fade"
                            transitionDuration={200}
                            label="Add more information for the project being created. This is for your reference only."
                          >
                            <ActionIcon>
                              <InfoCircle size={18} strokeWidth={2} color={'#fab005'} />
                            </ActionIcon>
                          </Tooltip>
                        </Flex>
                      </Col>
                      <Col lg="6" md="8" sm="8" xs="6" align="left">
                        <TextInput
                          radius={'xl'}
                          placeholder={`Project Description`}
                          // size="md"
                          onChange={(e) => {
                            formik.setFieldValue('projectDescription', e.target.value);
                          }}
                          value={formik.values.projectDescription}
                          error={formik.errors.projectDescription}
                          // disabled={formik.isSubmitting}
                        ></TextInput>
                      </Col>
                    </Group>
                  </Row>
                  <Row>
                    <Group spacing="lg">
                      <Col lg="3" md="3" sm="3" xs="3" align="right">
                        <Text>Project Type</Text>
                      </Col>
                      <Col lg="6" md="8" sm="8" xs="6" align="left">
                        <Select
                          placeholder="Project Type"
                          radius={'lg'}
                          required
                          rightSection={<IconChevronDown size={14} />}
                          rightSectionWidth={30}
                          styles={{ rightSection: { pointerEvents: 'none' } }}
                          data={projectTypeList}
                          value={formik.values.projectType}
                          onChange={(e) => {
                            formik.setFieldValue('projectType', e);
                          }}
                          error={formik.errors.projectType}
                          // disabled={formik.isSubmitting}
                        />
                      </Col>
                    </Group>
                  </Row>
                  <Row>
                    <Group spacing="lg">
                      <Col lg="3" md="3" sm="3" xs="3" align="right">
                        <Text>Project Year</Text>
                      </Col>
                      <Col lg="6" md="8" sm="8" xs="6" align="left">
                        <Select
                          placeholder="Project Year"
                          radius={'lg'}
                          required
                          rightSection={<IconChevronDown size={14} />}
                          rightSectionWidth={30}
                          styles={{ rightSection: { pointerEvents: 'none' } }}
                          data={['2020', '2021', '2022', '2023', '2024','2025','2026']}
                          value={formik.values.projectYear}
                          onChange={(e) => {
                            formik.setFieldValue('projectYear', e);
                          }}
                          error={formik.errors.projectYear}
                          // disabled={formik.isSubmitting}
                        />
                      </Col>
                    </Group>
                  </Row>
                </Flex>
              </Card.Body>
              <Card.Body>
                <Col>
                  <Group position="apart" spacing="xs">
                    <Text
                      size="lg"
                      weight={700}
                      color="yellow"
                      align="left"
                      style={{ fontSize: '18px' }}
                    >
                      Materials
                    </Text>
                    <Button
                      radius="xl"
                      color={'yellow'}
                      rightIcon={<IconPlus size={14} color="white" />}
                      onClick={() => setShowAddMaterialModal(true)}
                    >{`Add Material`}</Button>
                  </Group>
                </Col>
              </Card.Body>
              <Card.Body>
                <div className="table-responsive-sm">
                  <Table hover>
                    <thead>
                      <tr>
                        <th> </th>
                        <th>
                          <Center fw={500}>LIQUID</Center>
                        </th>
                        <th>
                          <Center fw={500}>BRAND</Center>
                        </th>
                        <th>
                          <Center fw={500}>SKU Description</Center>
                        </th>
                        <th>
                          <Center fw={500}>Production Date</Center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials?.map((material, index) => (
                        <tr key={index}>
                          <td align="center">
                            <ActionIcon variant="light" color={'yellow'} radius="xl">
                              <IconCopy
                                size={16}
                                color="black"
                                onClick={() => {
                                  setShowCloneMaterialModal(true);
                                  setSelectedMaterial(material);
                                  setSelectedMaterialIndex(index);
                                }}
                              />
                            </ActionIcon>
                          </td>
                          <td>{material?.liquid ? material?.liquid : '-'}</td>
                          <td style={{ color: '#fab005' }}>
                            {material?.brand ? material?.brand : '-'}
                          </td>
                          <td>
                            {material?.fgType === 'Virtual'
                              ? material?.virtualSku
                              : material?.skuDescription}
                          </td>
                          <td>
                            {material?.plants?.length > 0 &&
                              new Date(
                                Math.min(
                                  ...material.plants.map((plant) =>
                                    new Date(plant.production_date).getTime(),
                                  ),
                                ),
                              ).toLocaleDateString()}
                          </td>
                          <td>
                            <ActionIcon variant="light" color={'yellow'} radius="xl">
                              <IconEdit
                                size={16}
                                color="black"
                                onClick={() => {
                                  //console.log('edit ', material);
                                  setShowEditMaterialModal(true);
                                  setSelectedMaterial(material);
                                  setSelectedMaterialIndex(index);
                                }}
                              />
                            </ActionIcon>
                          </td>
                          <td>
                            <ActionIcon variant="light" color={'yellow'} radius="xl">
                              <IconTrash
                                size={16}
                                color="black"
                                onClick={() => {
                                  setShowDeleteMaterialModal(true);
                                  setSelectedMaterial(material);
                                  setSelectedMaterialIndex(index);
                                  // deleteMaterial(index);
                                }}
                              />
                            </ActionIcon>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <Card.Body>
                  <Group position="right" spacing="xl">
                    {/* <Button color="gray" radius="xl"
                      onClick={() => {
                        setProjectDraftStatus(true)
                        formik.handleSubmit(formik.values)
                      }
                      }
                    >{`Save Draft`}
                    </Button> */}
                    <Button
                      color="yellow"
                      align="right"
                      radius="xl"
                      className="btn btn-primary"
                      style={{ borderRadius: '40px' }}
                      leftIcon={<IconCheck size={20} color="white" />}
                      onClick={formik.handleSubmit}
                    >{`Submit`}</Button>
                  </Group>
                </Card.Body>
              </Card.Body>
            </Card>
          ) : (
            (() => {
              return (
                <Card>
                  <Card.Body>
                    <Group position="apart">
                      <Text
                        size="xl"
                        weight={700}
                        color="yellow"
                        align="left"
                      >{`Project Request`}</Text>
                    </Group>
                  </Card.Body>
                  <Card.Body>
                    <Group position="apart">
                      <Text align="left">
                        {`Sorry, but it seems that you do not have the required permissions to access or view this page.`}
                        <br />
                        {`Please contact the administrator to request access to this page.`}
                        <br />
                        {`Thank you.`}
                        <p>
                          <Link to="/role-request">Click Here</Link> to request the administrator
                          for access.
                        </p>
                      </Text>
                    </Group>
                  </Card.Body>
                </Card>
              );
            })()
          )}
          <AddMaterial
            showAddMaterialModal={showAddMaterialModal}
            closeAddMaterialModal={() => setShowAddMaterialModal(false)}
            addMaterial={addMaterial}
          />
          <EditMaterial
            showEditMaterialModal={showEditMaterialModal}
            closeEditMaterilaModal={() => setShowEditMaterialModal(false)}
            material={selectedMaterial}
            updateMaterial={updateMaterial}
          />
          <CloneMaterial
            showCloneMaterialModal={showCloneMaterialModal}
            closeCloneMaterilaModal={() => setShowCloneMaterialModal(false)}
            material={selectedMaterial}
            addMaterial={addMaterial}
          />
          <DeleteMaterial
            showDeleteMaterialModal={showDeleteMaterialModal}
            closeDeleteMaterialModal={() => setShowDeleteMaterialModal(false)}
            deleteMaterial={deleteMaterial}
          />
        </>
      )}
    </div>
  );
}
